
import { defineComponent, onMounted, ref, reactive,provide, nextTick } from "vue";
import store from "@/store";
import router from "@/router";
import iLeftHome from "@/module/LeftHome.vue";
import iFooterHome from "./module/FooterHome.vue";
import iGuestId from "./module/GuestId.vue";

import {message} from "ant-design-vue";
export default defineComponent({
  name: "App",
  components: {
    "v-footerHome": iFooterHome,
    "v-leftHome": iLeftHome,
    "v-GuestId":iGuestId,
  },
  
  setup() {
    const showPage = ref<boolean>(true)
    const onRefresh = () => {
      showPage.value = false
      nextTick(() => {
        showPage.value = true
      })
    }
    provide('', onRefresh)
    //抽屉
    const homevisible = ref<boolean>(false);

    const showDrawer = () => {
      homevisible.value = true;
    };
    //const userInfo = ref(null);
    let userInfo= ref<any>(null);
    function gotoPurhome() {
      router.replace("/PurHome");
    }
     
    function delteleToken() {
      message.success("已成功退出账户");
      window.localStorage.clear();
      setTimeout(() => {
        window.location.reload()
      }, 1000);
    }
    const data = reactive({
      time: 0,
      userId:"",
      comment:"0",
      return:false,
    });
    let referrer = window.localStorage.getItem("referrer");
    let tempStore = store;
    if (tempStore.state.userInfo!) {
      userInfo = JSON.parse(tempStore.state.userInfo!);
      data.userId=userInfo.id;
      
    }

    onMounted(() => {
      var StoreTemp = store;
      if (!StoreTemp.state.token) {
        StoreTemp.commit("saveToken", window.localStorage.Token);
        StoreTemp.commit("saveUserInfo", window.localStorage.UserInfo);
        userInfo.value = JSON.parse(StoreTemp.state.userInfo!);
      }
    });

    return {
      homevisible,
      showPage,
      showDrawer,
      userInfo,
      delteleToken,
      gotoPurhome,
      HeadPortraitUrl: 'http://www.aowok.com/upload/img/User/HeadPortrait/',data
    };
  },
});
