import { createStore } from "vuex";

export default createStore({
  state: {
    token: null,
    api:"https://www.aowok.com",//https://www.aowok.com、http://localhost:50332
    userInfo: null,
    Author: null,
    Question: null,
    AowokArticle: null,
    Banner: null,
    Article: null,
    UserRead: null,
    LogNow: null,
    UserArt: null,
    UserQues: null,
    UserInfoList:null,
    PurHomeList:null,
  },
  mutations: {
    saveToken(state, data) {
      if(data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.token = data;
      window.localStorage.setItem("Token", data);
    },
    saveUserArt(state, data) {
      if(data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.UserArt = data;
      window.localStorage.setItem("UserArt", data);
    },
    saveUserQues(state, data) {
      if(data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      console
      state.UserQues = data;
      window.localStorage.setItem("UserQues", data);
    },
    saveUserInfo(state,data) {
      if(data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.userInfo = data;
      // window.localStorage.setItem("UserInfo", Encrypts(Base64.toBase64(JSON.stringify(data)),'581a8f8f94ee48e4'));
      window.localStorage.setItem("UserInfo", data);
    },
    saveUserRead(state,data) {
      if(data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.UserRead = data;
      window.localStorage.setItem("UserRead", data);
    },
    saveAuthor(state,data) {
      if(data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.Author = data;
      window.localStorage.setItem("Author", data);
    },
    saveQuestion(state,data) {
      if(data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.Question = data;
      window.localStorage.setItem("Question", data);
    },
    saveAowokArticle(state,data) {
      if(data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.AowokArticle = data;
      window.localStorage.setItem("AowokArticle", data);
    },
    saveArticle(state,data) {
      if(data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.Article = data;
      window.localStorage.setItem("Article", data);
    },
    saveUserInfoList(state,data) {
      if(data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.UserInfoList = data;
      window.localStorage.setItem("UserInfoList", data);
    },
    saveBanner(state,data) {
      if(data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.Banner = data;
      window.localStorage.setItem("Banner", data);
    },
    saveLogNow(state,data) {
      if(data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.LogNow = data;
      window.localStorage.setItem("LogNow", data);
    },
    savePurHomeList(state,data) {
      if(data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.PurHomeList= data;
      window.localStorage.setItem("PurHomeList", data);
    },
  },
  actions: {},
  modules: {},
});
