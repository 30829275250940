const S4=function(){
 return(((1+Math.random())*0x10000|0).toString(16).substring(1));
}
let TimeOneDay=function(){
    var date = new Date();
    var year = date.getFullYear().toString().substring(2,4); //月份从0~11，所以加一
    var dateArr = [
        date.getMonth() + 1,
        date.getDate(),
    ];
    var month=date.getMonth() >9? (date.getMonth()+1).toString() : "0"+(date.getMonth()+1).toString() ;
    var dates=date.getDate() >9? (date.getDate()).toString() : "0"+(date.getDate()).toString() ;
    //如果格式是MM则需要此步骤，如果是M格式则此循环注释掉
    var strDate =year + month + dates;//+dateArr[2] +dateArr[3] +dateArr[4]
    return(strDate);
}
let TimeOneHour=function(){
    var date = new Date();
    var year = date.getFullYear().toString().substring(2,4); //月份从0~11，所以加一
    var dateArr = [
        date.getMonth() + 1,
        date.getDate(),
        date.getHours(),
        date.getSeconds(),
    ];
    var month=date.getMonth() >9? (date.getMonth()+1).toString() : "0"+(date.getMonth()+1).toString() ;
    var dates=date.getDate() >9? (date.getDate()).toString() : "0"+(date.getDate()).toString() ;
    var hours=date.getHours() >9? (date.getHours()).toString() : "0"+(date.getHours()).toString() ;
    var seconds=date.getSeconds() >9? (date.getSeconds()).toString() : "0"+(date.getSeconds()).toString() ;
    //如果格式是MM则需要此步骤，如果是M格式则此循环注释掉
    var strDate =year + month + dates+hours+seconds;//+dateArr[2] +dateArr[3] +dateArr[4]
    return(strDate);
}
let TimeChinaDate=function(){
    var date = new Date();
    var year = date.getFullYear().toString(); //月份从0~11，所以加一
    var month=date.getMonth() >9? (date.getMonth()+1).toString() : "0"+(date.getMonth()+1).toString() ;
    var dates=date.getDate() >9? (date.getDate()).toString() : "0"+(date.getDate()).toString() ;
    var hours=date.getHours() >9? (date.getHours()).toString() : "0"+(date.getHours()).toString() ;
    var minute=date.getMinutes();
    var seconds=date.getSeconds() >9? (date.getSeconds()).toString() : "0"+(date.getSeconds()).toString() ;
    //如果格式是MM则需要此步骤，如果是M格式则此循环注释掉
    var strDate =year +"年"+ month +"月"+ dates+"日 "+hours+":"+minute+":"+seconds+"";//+dateArr[2] +dateArr[3] +dateArr[4]
    return(strDate);
}

const OneDayPg=function(){
    var date = new Date();
    var year = date.getFullYear().toString().substring(2,4); //月份从0~11，所以加一
    var dateArr = [
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    ];
    //如果格式是MM则需要此步骤，如果是M格式则此循环注释掉
    var strDate =dateArr[0] +dateArr[1] +dateArr[2]+dateArr[3]
    return(strDate);
}

export function guid4(){
    return(S4());
}
export function guid8(){
    return(S4()+S4());
}
export function guid16(){
    return(S4()+S4()+S4()+S4());
}
export function guid32(){
    return(S4()+S4()+S4()+S4()+S4()+S4()+S4()+S4());
}
export function TimeyyMMdd(){
    return(TimeOneDay());
}
export function TimeyyMMddHHmm(){
    return(TimeOneHour());
}
export function TimeChina(){
    return(TimeChinaDate());
}
export function Pg(){
    return(OneDayPg());
}