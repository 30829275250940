
import { defineComponent, onMounted, ref, reactive } from "vue";
import { Modal, message } from "ant-design-vue";
import { timeago2Date,todayUpAndWeek } from "@/store/formatTime";
import Vcode from "vue3-puzzle-vcode";
import store from "@/store";
import request from "@/api/http";
import router from "@/router";

export default defineComponent({
  name: "LetterLove",
  components: {
    Vcode,
  },
  setup() {
    const formState = reactive({
      string: [""],
    });
    const formStateOut = reactive({
      string: [""],
      date:"",
      time:"",
    });
    const value = ref([]);
    const isShow = ref(false);
    const onShow = () => {
      isShow.value = true;
      handleSendEamil();
    };

    const onClose = () => {
      isShow.value = false;
    };
    const onSuccess = () => {
      onClose(); // 验证成功，手动关闭模态框
    };

    const blackContact = ref([
      {
        value: "抖音",
        label: "抖音",
      },
      {
        value: "微信",
        label: "微信",
      },
      {
        value: "QQ",
        label: "QQ",
      },
      {
        value: "支付宝",
        label: "支付宝",
      },
    ]);
    const contact = ref([
      {
        value: "抖音",
        label: "抖音",
      },
      {
        value: "微信",
        label: "微信",
      },
      {
        value: "QQ",
        label: "QQ",
      },
      {
        value: "支付宝",
        label: "支付宝",
      },
      {
        value: "办公地址",
        label: "办公地址",
      },
      {
        value: "家庭地址",
        label: "家庭地址",
      },
      {
        value: "老地方",
        label: "老地方",
      },
    ]);
    const touch = ref([
      {
        value: "见面",
        label: "见面",
      },
      {
        value: "牵手",
        label: "牵手",
      },
      {
        value: "接吻",
        label: "接吻",
      },
      {
        value: "拥抱",
        label: "拥抱",
      },
      {
        value: "同居",
        label: "同居",
      },
    ]);
    const purImpression = ref([
      {
        value: "刚刚认识不太了解",
        label: "刚刚认识不太了解",
      },
      {
        value: "已学习缘梦入门教程",
        label: "已学习缘梦入门教程",
      },
      {
        value: "已注册学员账户",
        label: "已注册学员账户",
      },
      {
        value: "已参加过直播",
        label: "已参加过直播",
      },
      {
        value: "看重缘梦的心理学",
        label: "看重缘梦的心理学",
      },
      {
        value: "看重缘梦的性格分析",
        label: "看重缘梦的性格分析",
      },
      {
        value: "乐意互助我接受公开指导",
        label: "乐意互助我接受公开指导",
      },
      {
        value: "需要私密的付费指导",
        label: "需要私密的付费指导",
      },
    ]);
    const handleSendEamil = () => {
      if (formState.string.join().length < 250) {
        return message.error("提交失败！你提交的信息过少。", 10);
      } else if (formState.string[2] == null) {
        return message.error("提交失败！请填写第2项-联系方式。", 10);
      } else if (formState.string[33] == null) {
        return message.error("提交失败！请填写第33项-你对缘梦的认识程度。", 10);
      } else {
        formStateOut.string[0] = "老师您好！我有一些恋爱的问题需要帮助，"+formState.string[0];
        if (formState.string[1] != null) {
          formStateOut.string[1] = "我名字叫做" + formState.string[1];
        }
        if (formState.string[2] != null) {
          formStateOut.string[2] =
            "【联系方式如下" + formState.string[2] + "】";
        }
        if (formState.string[3] != null) {
          formStateOut.string[3] = "我的职业是" + formState.string[3] + "";
        }
        if (formState.string[4] != null) {
          formStateOut.string[4] =
            "在【" + formState.string[4] + "】生活和工作";
        }
        if (formState.string[5] != null) {
          formStateOut.string[5] = "今年" + formState.string[5] + "岁";
        }
        if (formState.string[6] != null) {
          formStateOut.string[6] = "身高【" + formState.string[6] + "】";
        }
        if (formState.string[7] != null) {
          formStateOut.string[7] =
            "自评的容貌是【" + formState.string[7] + "分";
        }
        if (formState.string[8] != null) {
          formStateOut.string[8] = "性格" + formState.string[8];
        }
        if (formState.string[9] != null) {
          formStateOut.string[9] = "学历是" + formState.string[9];
        }
        if (formState.string[10] != null) {
          formStateOut.string[10] = "爱好与朋友圈子是" + formState.string[10];
        }
        if (formState.string[11] != null) {
          formStateOut.string[11] = "我的优点是" + formState.string[11];
        }
        if (formState.string[12] != null) {
          formStateOut.string[12] = "我的缺点是" + formState.string[12];
        }
        if (formState.string[13] != null) {
          formStateOut.string[13] =
            "我觉得我们直接的问题是" + formState.string[13];
        }
        if (formState.string[14] != null) {
          formStateOut.string[14] = "他的职业是" + formState.string[14];
        }
        if (formState.string[15] != null) {
          formStateOut.string[15] =
            "他是在【" + formState.string[15] + "】工作生活";
        }
        if (formState.string[16] != null) {
          formStateOut.string[16] = "他" + formState.string[16] + "岁";
        }
        if (formState.string[17] != null) {
          formStateOut.string[17] = "身高【" + formState.string[17] + "】";
        }
        if (formState.string[18] != null) {
          formStateOut.string[18] =
            "我觉得他的容貌是值【" + formState.string[18] + "分";
        }
        if (formState.string[19] != null) {
          formStateOut.string[19] = "他的性格" + formState.string[19];
        }
        if (formState.string[20] != null) {
          formStateOut.string[20] =
            "他的爱好与朋友圈子是" + formState.string[20];
        }
        if (formState.string[21] != null) {
          formStateOut.string[21] =
            "他喜欢的食物零食、书籍和杂志、歌曲是" + formState.string[21];
        }
        if (formState.string[22] != null) {
          formStateOut.string[22] =
            "目前" + formState.string[22] + "的账号已经被拉黑";
        }
        if (formState.string[23] != null) {
          formStateOut.string[23] =
            "仍可联系的方式包括有" + formState.string[23];
        }
        if (formState.string[24] != null) {
          formStateOut.string[24] = "我们过去约会的情况" + formState.string[24];
        }
        if (formState.string[25] != null) {
          formStateOut.string[25] = "约会会做的事" + formState.string[25];
        }
        if (formState.string[26] != null) {
          formStateOut.string[26] = "不见面的时候" + formState.string[26];
        }
        if (formState.string[27] != null) {
          formStateOut.string[27] = "我们会深入聊" + formState.string[27];
        }
        if (formState.string[28] != null) {
          formStateOut.string[28] = "哭过" + formState.string[28];
        }
        if (formState.string[29] != null) {
          formStateOut.string[29] = "我们试过" + formState.string[29];
        }
        if (formState.string[30] != null) {
          formStateOut.string[30] =
            "我们已经一起【" + timeago2Date(formState.string[30]) + "】";
        }
        if (formState.string[31] != null) {
          formStateOut.string[31] = "爱情没有一帆风顺的" + formState.string[31];
        }
        if (formState.string[32] != null) {
          formStateOut.string[32] = "也曾经伤害过对方" + formState.string[32];
        }
        if (formState.string[33] != null) {
          formStateOut.string[33] =
            "我目前对缘梦了解是" +
            formState.string[33] +
            "希望老师能帮我解决难题。";
        }
        // //console.log("传参信息:", formState.string.join("。"));

        request({
          url: "/HomeApi/SendEmail",
          method: "post",
          data: {
            url: router.currentRoute.value.fullPath,
            comment: formStateOut.string.join(),
            book:formStateOut.date+","+formStateOut.time,
          },
        }).then((res: any) => {
          if (res.data.success) {
            message.success(
              "你的求助信息已提交至导师邮箱，我们将尽快给与回复，感谢你对本真缘梦的支持。",
              5
            );
            formStateOut.string = [];
            formState.string = [];
          } else {
            message.error(res.data.msg);
          }
        });
      }
    };
    return {
      onShow,
      isShow,
      onClose,
      onSuccess,
      handleSendEamil,
      formState,
      labelCol: { style: { width: "80px" } },
      wrapperCol: { span: 14 },
      timeago2Date,
      blackContact,
      contact,
      touch,
      purImpression,
      todayUpAndWeek,
      formStateOut,
    };
  },
});
