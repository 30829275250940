import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

 import Antd from 'ant-design-vue';
 //import {Button,Col,Row,Modal,Carousel,TabPane,Card,Tabs,Drawer,Textarea,Input,Select,Switch,Collapse,Form, ConfigProvider} from 'ant-design-vue';

// import 'ant-design-vue/dist/reset.css';
// import VuePictureCropper from 'vue-picture-cropper';

var app = createApp(App);
app.config.errorHandler = (err) => {
    if(store.state.api=="https://www.aowok.com" ){
        //console.log(err);
    }
};//禁止显示Api错误
app.config.warnHandler= (err) => {
    if(store.state.api=="https://www.aowok.com" ){
        //console.log(err);
    }
};//禁止显示黄色错误reset
// app.use(Button).use(Col).use(Row).use(Modal).use(Form).use(Input).use(Select).use(Switch).use(Carousel).use(TabPane).use(Card).use(Tabs).use(Drawer).use(Textarea).use(Collapse).use(ConfigProvider)
;
app.use(Antd);
// app.use(Button);
//app.config.globalProperties.$message = message;ConfigProvider
// app.use(store).use(router).mount('#app')
app.use(store).use(router)
app.mount('#app')