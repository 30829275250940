
import { defineComponent, onMounted, ref, reactive } from "vue";
import { Modal, message } from "ant-design-vue";
import Vcode from "vue3-puzzle-vcode";
import store from "@/store";
import request from "@/api/http";
import router from "@/router";
import { number } from "echarts/core";
export default defineComponent({
  name: "LetterCharacter",
  components: {
    Vcode,
  },
  setup() {
    const formState = reactive({
      string:[] as number[],
      ques: ["","1.当我哭泣时，我知道是什么原因","2.空想纯粹是浪费时间","3.我希望自己不那么害羞","4.我常搞不清自己是什么样的感受","5.我常幻想着将来","6.我似乎交朋友和别人一样容易","7.知道问题的答案比知道其原因更重要","8.我难以用恰当的词描述自己的情感","9.我喜欢别人知道我对事物的态度","10.有些身体感觉连医生也不理解","11.只做工作是不够的我需知道为何做和如何做好","12.我很容易的描述自己的情感","13.我更喜欢分析问题而不仅仅描述它","14．当我心烦意乱时，我不知是伤心、害怕、愤怒？","15.我常好幻想","16.当我无事可做时，常好空想","17.我常为体内的感觉所困惑","18.我极少做白日梦","19.我更关心事情的发生，而不注意为何发生","20.我有些难以识别的感受","21.情感的沟通是很重要的","22.我觉得难以描述对别人的情感","23.别人告诉我，要更多的表达自己的感受","24.一个人应寻求更深刻的理解","25.我不知道我的内心发生了什么","26.我常常不知道自己为什么生气"],
      disabled:8,
      sum:1,
      list:1,
      activeKey:1,
      onSuccess:1,
      fen1:0,
      fen2:0,
      fen3:0,
      fen4:0,
      res1:"",
      res2:"",
      res3:"",
      res4:"",
    });
    const value = ref([]);
    const isShow = ref(false);
    const onShow = () => {
      isShow.value = true;
    };
    const radioSt = reactive({
      display: 'flex',
      height: '30px',
      lineHeight: '30px',
    });
    const onClose = () => {
      isShow.value = false;
    };
    const onSuccess = () => {
      onClose(); // 验证成功，手动关闭模态框
      handleSendEamil();
    };
    const countDown = () => {
      if (formState.disabled > 0) {
        formState.disabled--;
        setTimeout(countDown, 1000);
      }
      else {
        formState.disabled=0;
      }
    }
    countDown();
    const handleList= (e: any) => {
      formState.list = e
      if(e==1)
      {
        formState.activeKey =1;
      }
      if(e==2)
      {
        formState.activeKey =11;
      }
      if(e==3)
      {
        formState.activeKey =21;
      }
      if(e==4)
      {
        formState.activeKey =31;
      }
    
    };
    const handleSum= (e: any) =>{
      if(e >=formState.sum)
      {
        formState.sum = formState.sum+1;
        formState.activeKey=e+1;
        formState.fen1=formState.string[1]+formState.string[2]+formState.string[3];
      }
      else if (e <formState.sum)
      {
        formState.activeKey=e+1; 
      }
      if(e==10)
      {
        formState.list=2;
        formState.activeKey =11;
      }
      if(e==20)
      {
        formState.list=3;
        formState.activeKey =21;
      }
      if(e==30)
      {
        formState.list=4;
        formState.activeKey =31;
      }

      
    }
    const handleSuccess= (e: any) =>{
      formState.onSuccess=e;
      if(e=3)
      {
        onS();
      }
    }
    const onS=() =>{
      //formState.string[0]=0;
      //formState.string=[1,2,4,2,4,2,5,4,3,2,1,2,3,4,5,5,4,3,2,1,2,3,2,1,5,4,3,2,4,3,2];
      //formState.string=[0, 5, 5, 1, 1, 1, 3, 3, 1, 5, 3, 2, 1, 1, 1, 5, 5, 2, 5, 3, 1, 1, 1, 1, 5, 1, 1 ];
      formState.fen1=formState.string[4]+formState.string[8]+formState.string[12]+formState.string[22]+formState.string[23]+formState.string[26];//6题 15.2分 17.7分 12.7 20.3分
      //按平均66分/26题=2.538 
      formState.fen2=formState.string[1]+formState.string[3]+formState.string[10]+formState.string[14]+formState.string[17]+formState.string[20]+formState.string[25];//7题 17.7分
      formState.fen3=formState.string[2]+formState.string[5]+formState.string[15]+formState.string[16]+formState.string[18];//5题 12.7
      formState.fen4=formState.string[6]+formState.string[7]+formState.string[9]+formState.string[11]+formState.string[13]+formState.string[19]+formState.string[21]+formState.string[24];//8题 20.3分
      formState.res1=formState.fen1>15.2?"↑":"↓";
      formState.res2=formState.fen2>17.7?"↑":"↓";
      formState.res3=formState.fen3>12.7?"↑":"↓";
      formState.res4=formState.fen4>20.3?"↑":"↓";
      //console.log(formState.string);
    }
    const handleSendEamil = () => {
      if (formState.string.join().length<250 ) {
        return message.error("提交失败！你提交的信息过少。", 10);
      } 
      else if (formState.string[2]==null ) {
        return message.error("提交失败！请填写第2项联系方式。", 10);
      }      
      else if (formState.string[34]==null) {
        return message.error("提交失败！请填写第36项关于缘梦。", 10);
      }   
      else {
        // //console.log('传参信息:', formState.string.join());
        request({
          url: "/HomeApi/SendEmail",
          method: "post",
          data: {
            url: router.currentRoute.value.fullPath,
            comment: formState.string.join(),
          },
        }).then((res: any) => {
          if (res.data.success) {
            message.success(
              "你的求助信息已提交至导师邮箱，我们将尽快给与回复，感谢你对本真缘梦的支持。",
              5
            );
          } else {
            message.error(res.data.msg);
          }
        });
      }
    };
    return {
      onShow,
      isShow,
      onClose,
      onSuccess,
      handleSendEamil,
      handleList,
      formState,
      labelCol: { style: { width: "80px" } },
      wrapperCol: { span: 14 },
      handleSum,
      handleSuccess,
      radioSt,
    };
  },
});
