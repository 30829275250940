
import { defineComponent, toRefs, ref, reactive } from "vue";
import { TimeyyMMddHHmm } from "@/store/guid";
import request from "@/api/http";
import router from "@/router";
import store from "@/store";
interface DelayLoading {
  delay: number;
}
export default defineComponent({
  name: "AowokOneDay",
  components: {
  },
  props: {
    key: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    keyWord: {
      type: String,
      default: "",
    },
    keyWordId: {
      type: String,
      default: "",
    },
    imagesId: {
      type: String,
      default: "",
    },
    addTime: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    descriptions: {
      type: String,
      default: "",
    },
    loginName: {
      type: String,
      default: "",
    },
    HeadPortrait: {
      type: String,
      default: "",
    },
    urlId: {
      type: String,
      default: "",
    },
    userLogin:{
      type: Boolean,
      default: "", 
    }
  },
  setup(props: any) {
    toRefs(props);
    const formmen = reactive({
      switchReadPointsOpen: true,
      switchShareLearnedOpen: true,
      switchLookMe: true,
      level: 0,
      levelLine: 0,
      userClass: 1,
      userClassName: "注册学员",
      loginResetPassWord: null,
      confirmPassWord: null,
      loginNewPassWord: null,
    });

    let userRead = reactive({
      miniToken: "",
      time: "",
      id: "",
      href: "",
      title: "",
      image:"",
      rehref: "",
    });
    let tempStore = store;
    function gotoR(id: string,title: string,image: string) {
      if(props.userLogin==false)
      {
        window.location.href = store.state.api+'/a/' + id + '.html';
      }else
      {
        userRead = JSON.parse(tempStore.state.UserRead!);//userInfo.addTime
      userRead.title=title;
      userRead.image=image;
      userRead.rehref = window.location.pathname+window.location.search ;
      userRead.href=store.state.api+'/a/' + id + '.html';
      //userRead.href='http://localhost:50332/a/' + id + '.html';
      if (userRead.id == "0") {
        userRead.id =id;
      }
      else if (userRead.id.indexOf(id) !== -1) {
        userRead.id = userRead.id + "," + id;
        
      }
      store.commit("saveUserRead", JSON.stringify(userRead)); //保存 tokenurlId+"#"+
      // //console.log(userRead);
      // window.location.href = "http://localhost:44512/r/"+userRead.miniToken;
      // router.replace({ name: 'r', params: { id: userRead.miniToken } });//{path:’./orderList’,query:{id:2,name:’xiaoming’}
      router.push({ name: 'r', params: { id: userRead.miniToken }}) // -> /user/123
      //router.push({ name: 'q', query:{id:userRead.miniToken,name:'id'}}) // -> /user/123
      //router.push({ path: `/r/${userRead.miniToken}` }) // -> /user/123
      // window.location.open({ path: `/r/${userRead.miniToken}` }) // -> /user/123
      }
      
      
    }
    const disabledShareLearnedOpen = ref<boolean | DelayLoading>(false);
    const shareLearnedOpen = () => {
      disabledShareLearnedOpen.value = { delay: 1000 };

      var returnData = 0;
      if (formmen.switchShareLearnedOpen == true) {
        returnData = 1;
      } else if (formmen.switchShareLearnedOpen == false) {
        returnData = 2;
      }
      setTimeout(() => {
        disabledShareLearnedOpen.value = !disabledShareLearnedOpen.value;
      }, 10000);

    };
    return {
      shareLearnedOpen,
      TimeyyMMddHHmm,
      disabledShareLearnedOpen,
      gotoR,
      userRead,
    }
  },
});
