import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a8a9c94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "aaTopBox" }
const _hoisted_2 = { class: "aaBox" }
const _hoisted_3 = ["title", "onClick"]
const _hoisted_4 = ["title"]
const _hoisted_5 = ["title"]
const _hoisted_6 = ["title", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tag_outlined = _resolveComponent("tag-outlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "aaBoxImg",
      style: _normalizeStyle({ 'background-image': `url(${require('@/assets/Collocation/MS/' + Math.round(Math.random() * 144) + '.jpg')})`, })
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagList.slice(0,1), (item) => {
          return (_openBlock(), _createElementBlock("a", {
            class: _normalizeClass('aaTagTop '+item),
            color: "default",
            key: item,
            title: item,
            onClick: ($event: any) => (_ctx.gotoQuestionTag(item))
          }, [
            _createVNode(_component_tag_outlined),
            _createTextVNode(" " + _toDisplayString(item), 1)
          ], 10, _hoisted_3))
        }), 128)),
        _createElementVNode("a", {
          title: _ctx.title,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.gotoDetails(_ctx.id)))
        }, [
          _createElementVNode("h4", {
            class: "aatitle",
            title: _ctx.title
          }, _toDisplayString(_ctx.title), 9, _hoisted_5)
        ], 8, _hoisted_4),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagList.slice(1,4), (tagName) => {
          return (_openBlock(), _createElementBlock("a", {
            class: "aaTag",
            color: "default",
            key: tagName,
            title: tagName,
            onClick: ($event: any) => (_ctx.gotoQuestionTag(tagName))
          }, _toDisplayString(tagName), 9, _hoisted_6))
        }), 128))
      ])
    ], 4)
  ]))
}