
import { defineComponent, toRefs } from "vue";
import {TagOutlined

} from '@ant-design/icons-vue';
import router from "@/router";
import store from "@/store";
import { Item } from "ant-design-vue/lib/menu";
export default defineComponent({
  name: "Question",
  components: {
    TagOutlined
  },
  props: {
    key: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    tag: {
      type: String,
      default: "",
    },
  },
  setup(props: any) {
    let { tag } = toRefs(props);
    let tagList = tag.value.split(",");
    function gotoQuestionTag(id: String) {
      router.push("/QuestionTag/" + id);
    }
    function gotoDetails(id: string) {
      router.push("/Qds/" + id);
    }

    return {
      tagList,
      gotoQuestionTag,
      gotoDetails,
      TagOutlined,
      Item,
    };
  },
});
