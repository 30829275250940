
import { defineComponent, toRefs } from "vue";
import {TagOutlined} from '@ant-design/icons-vue';
import router from "@/router";
import store from "@/store";
import { Item } from "ant-design-vue/lib/menu";
export default defineComponent({
  name: "Article",
  props: {
    content: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    cover: {
      type: String,
      default: "",
    },
    userName: {
      type: String,
      default: "",
    },
    createTime: {
      type: String,
      default: "",
    },
  },
  setup(props: any) {
    toRefs(props);
  },
});
