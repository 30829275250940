import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { Base64 } from "js-base64";
import {Decrypts} from "@/store/encrypt";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: ()=>import("@/views/Home.vue"),
    meta: {
      title: "首页",
      keepAlive: true,//显示页面内容
      keepLeft: true,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: true,//底部顯示/隱藏
    },
  },
  {
    path: "/BackRoute/:id",
    name: "BackRoute",
    component: ()=>import("@/views/BackRoute.vue"),
    meta: {
      title: "黑卡",
      keepAlive: false,//显示页面内容
      keepLeft: false,//右側隱藏false
      keepFooter: false,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/LogCharts",
    name: "LogCharts",
    component: ()=>import("@/views/LogCharts.vue"),
    meta: {
      title: "访客数据中心",
      keepAlive: false,//显示页面内容
      keepLeft: false,//右側隱藏false
      keepFooter: false,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/Free/:id",
    name: "Free",
    component: ()=>import("@/views/PurFree.vue"),
    meta: {
      title: "访客数据中心",
      keepAlive: false,//显示页面内容
      keepLeft: false,//右側隱藏false
      keepFooter: false,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/Register",
    name: "Register",
    component: ()=>import("@/views/Register.vue"),
    meta: {
      keepAlive: false,//显示页面内容
      keepLeft: false,//右側隱藏false
      keepFooter: false,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/ArticleList",
    name: "ArticleList",
    component: ()=>import("@/views/ArticleList.vue"),
    meta: {
      keepAlive: true,//显示页面内容
      keepLeft: true,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/QuestionList",
    name: "QuestionList",
    component: ()=>import("@/views/QuestionList.vue"),
    meta: {
      keepAlive: true,//显示页面内容
      keepLeft: true,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/QuestionTag/:id",
    name: "QuestionTag",
    component: ()=>import("@/views/QuestionTag.vue"),
    meta: {
      keepAlive: true,//显示页面内容
      keepLeft: true,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/ServeCard",
    name: "ServeCard",
    component: ()=>import("@/views/ServeCardList.vue"),
    meta: {
      keepAlive: true,//显示页面内容
      keepLeft:false,//右側隱藏falseServeCard
      keepFooter: true,//底部顯示/隱藏MBCT
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/MBCT",
    name: "MBCT",
    component: ()=>import("@/views/MBCT.vue"),
    meta: {
      keepAlive: false,//显示页面内容
      keepLeft:false,//右側隱藏falseServeCard
      keepFooter: false,//底部顯示/隱藏MBCT
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/video/:id",
    name: "video",
    component: ()=>import("@/views/Video.vue"),
    meta: {
      keepAlive: true,//显示页面内容
      keepLeft: false,//右側隱藏false
      keepFooter: false,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/r/:id",
    name: "r",
    component: ()=>import("@/views/r.vue"),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      keepAlive: false,//显示页面内容
      keepLeft: false,//右側隱藏false
      keepFooter: false,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
    },
    // redirect: '/r/:id'
    // children:[
    //   {path:"/r",redirect:'/r:id'},
    // ] 
  },
  {
    path: "/Letter/:id",
    name: "Letter",
    component: ()=>import("@/views/Letter.vue"),
    meta: {
      requireAuth: false, // 添加该字段，表示进入这个路由是需要登录的
      keepAlive: true,//显示页面内容
      keepLeft: true,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/QCR/:id",
    name: "QCR",
    component: ()=>import("@/views/QCR.vue"),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      keepAlive: false,//显示页面内容
      keepLeft: false,//右側隱藏false
      keepFooter: false,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/FCR/:id",
    name: "FCR",
    component: ()=>import("@/views/FCR.vue"),
    meta: {
      keepAlive: false,//显示页面内容
      keepLeft: false,//右側隱藏false
      keepFooter: false,//底部顯示/隱藏\
      keepGuestId: false,//底部顯示/隱藏
      
    },
  },
  {
    path: "/QCRTEA",
    name: "QCRTEA",
    component: ()=>import("@/views/QCRTEA.vue"),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      keepAlive: false,//显示页面内容
      keepLeft: false,//右側隱藏false
      keepFooter: false,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/Ads/:id",
    name: "Ads",
    props: true,
    component: ()=>import("@/views/Ads.vue"),
    meta: {
      keepAlive: true,//显示页面内容
      keepLeft: true,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/Qds/:id",
    name: "Qds",
    props: true,
    component: ()=>import("@/views/Qds.vue"),
    meta: {
      keepAlive: true,//显示页面内容
      keepLeft: true,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/purIntOutput",
    name: "purIntOutput",
    component: ()=>import("@/views/purInts/purIntOutput.vue"),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      keepAlive: true,//显示页面内容
      keepLeft: true,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: true,//底部顯示/隱藏
    },
  },
  {
    path: "/ShareConsult",
    name: "ShareConsult",
    component: ()=>import("@/views/ShareConsult.vue"),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      keepAlive: true,//显示页面内容
      keepLeft: true,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: true,//底部顯示/隱藏
    },
  },
  {
    path: "/PurHome",
    name: "PurHome",
    component: ()=>import("@/views/PurHome.vue"),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      keepAlive: true,//显示页面内容
      keepLeft: true,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: true,//底部顯示/隱藏
    },
  },
  {
    path: "/Login",
    name: "Login",
    component: ()=>import("@/views/Login.vue"),
    meta: {
      keepAlive: false,//显示页面内容
      keepLeft: false,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
     
    },
  },
  {
    path: "/PurCore/:id",
    name: "PurCore",
    component: ()=>import("@/views/PurCore.vue"),
    meta: {
      keepAlive: true,//显示页面内容
      keepLeft: true,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: true,//右側隱藏false
    },
  },
  {
    path: "/PurFeel/:id",
    name: "PurFeel",
    component: ()=>import("@/views/PurFeel.vue"),
    meta: {
      keepAlive: true,//显示页面内容
      keepLeft: true,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: true,//右側隱藏false
    },
  },
  {
    path: "/PurWork/:id",
    name: "PurWork",
    component: ()=>import("@/views/PurWork.vue"),
    meta: {
      keepAlive: true,//显示页面内容
      keepLeft: true,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: true,//右側隱藏false
    },
  },

  {
    path: "/PurChild/:id",
    name: "PurChild",
    component: ()=>import("@/views/PurChild.vue"),
    meta: {
      keepAlive: true,//显示页面内容
      keepLeft: true,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: true,//右側隱藏false
    },
  },
  {
    path: "/Inv/:id",
    name: "Inv",
    component: ()=>import("@/views/Inv.vue"),
    meta: {
      keepAlive: false,//显示页面内容
      keepLeft: false,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/QRead/",
    name: "QRead",
    component: ()=>import("@/views/QRead.vue"),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      keepAlive: true,//显示页面内容
      keepLeft: true,//右側隱藏false
      keepFooter: true,//底部顯示/隱藏
      keepGuestId: true,//底部顯示/隱藏
    },
  },
  {
  path: "/NoPage",
    name: "NoPage",
    component: ()=>import("@/views/NoPage.vue"),
    meta: {
      requireAuth:false, // 添加该字段，表示进入这个路由是需要登录的
      keepAlive: false,//显示页面内容
      keepLeft: false,//右側隱藏false
      keepFooter: false,//底部顯示/隱藏
      keepGuestId: false,//底部顯示/隱藏
    },
  },
  {
    path: "/Psychotheraphy",
      name: "Psychotheraphy",
      component: ()=>import("@/views/onepage/Psychotheraphy.vue"),
      meta: {
        requireAuth:false, // 添加该字段，表示进入这个路由是需要登录的
        keepAlive: false,//显示页面内容
        keepLeft: false,//右側隱藏false
        keepFooter: false,//底部顯示/隱藏Chinesecharacter
        keepGuestId: false,//底部顯示/隱藏
      },
    },
    {
      path: "/Psy",
        name: "Psy",
        component: ()=>import("@/views/onepage/Psy.vue"),
        meta: {
          requireAuth:false, // 添加该字段，表示进入这个路由是需要登录的
          keepAlive: false,//显示页面内容
          keepLeft: false,//右側隱藏false
          keepFooter: false,//底部顯示/隱藏Chinesecharacter
          keepGuestId: false,//底部顯示/隱藏
        },
      },
    {
      path: "/Clock/:id",
        name: "Clock",
        component: ()=>import("@/views/onepage/clock.vue"),
        meta: {
          requireAuth:false, // 添加该字段，表示进入这个路由是需要登录的
          keepAlive: false,//显示页面内容
          keepLeft: false,//右側隱藏false
          keepFooter: false,//底部顯示/隱藏Chinesecharacter
          keepGuestId: false,//底部顯示/隱藏
        },
      },
      {
        path: "/Chinesecharacter/:id",
          name: "Chinesecharacter",
          component: ()=>import("@/views/onepage/chinesecharacter.vue"),
          meta: {
            requireAuth:false, // 添加该字段，表示进入这个路由是需要登录的
            keepAlive: false,//显示页面内容
            keepLeft: false,//右側隱藏false
            keepFooter: false,//底部顯示/隱藏Chinesecharacter
            keepGuestId: false,//底部顯示/隱藏
          },
        },
        {
          path: "/chineseWords/:id",
            name: "chineseWords",
            component: ()=>import("@/views/onepage/chineseWords.vue"),
            meta: {
              requireAuth:false, // 添加该字段，表示进入这个路由是需要登录的
              keepAlive: false,//显示页面内容
              keepLeft: false,//右側隱藏false
              keepFooter: false,//底部顯示/隱藏Chinesecharacter
              keepGuestId: false,//底部顯示/隱藏
            },
          },
        {
          path: "/99",
            name: "99",
            component: ()=>import("@/views/onepage/99.vue"),
            meta: {
              requireAuth:false, // 添加该字段，表示进入这个路由是需要登录的
              keepAlive: false,//显示页面内容
              keepLeft: false,//右側隱藏false
              keepFooter: false,//底部顯示/隱藏Chinesecharacter
              keepGuestId: false,//底部顯示/隱藏
            },
          },
          {
            path: "/bisun/:id",
              name: "bisun",
              component: ()=>import("@/views/onepage/bisun.vue"),
              meta: {
                requireAuth:false, // 添加该字段，表示进入这个路由是需要登录的
                keepAlive: false,//显示页面内容
                keepLeft: false,//右側隱藏false
                keepFooter: false,//底部顯示/隱藏Chinesecharacter
                keepGuestId: false,//底部顯示/隱藏
              },
            },
          {
            path: "/992/:id",
              name: "992",
              component: ()=>import("@/views/onepage/992.vue"),
              meta: {
                requireAuth:false, // 添加该字段，表示进入这个路由是需要登录的
                keepAlive: false,//显示页面内容
                keepLeft: false,//右側隱藏false
                keepFooter: false,//底部顯示/隱藏Chinesecharacter
                keepGuestId: false,//底部顯示/隱藏
              },
            },
            {
              path: "/99yjf",
                name: "99yjf",
                component: ()=>import("@/views/onepage/99yjf.vue"),
                meta: {
                  requireAuth:false, // 添加该字段，表示进入这个路由是需要登录的
                  keepAlive: false,//显示页面内容
                  keepLeft: false,//右側隱藏false
                  keepFooter: false,//底部顯示/隱藏Chinesecharacter
                  keepGuestId: false,//底部顯示/隱藏
                },
              },
              {
                path: "/99kuo",
                  name: "99kuo",
                  component: ()=>import("@/views/onepage/99kuo.vue"),
                  meta: {
                    requireAuth:false, // 添加该字段，表示进入这个路由是需要登录的
                    keepAlive: false,//显示页面内容
                    keepLeft: false,//右側隱藏false
                    keepFooter: false,//底部顯示/隱藏Chinesecharacter
                    keepGuestId: false,//底部顯示/隱藏
                  },
                },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
import store from "@/store";
var storeTemp = store;
// 路由守卫
router.beforeEach((to, from, next) => {
  // var pathname=window.location.pathname;
  // //console.log(from.path);
  
  window.localStorage.setItem('referrer',from.path) //储存来源路由
  
      // 让页面回到顶部
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
  if (!storeTemp.state.token) {
    storeTemp.commit("saveToken", window.localStorage.Token);
    storeTemp.commit("saveUserInfo", window.localStorage.UserInfo);
  }
  if (to.meta.requireAuth) {
    // 判断该路由是否需要登录权限
    if (storeTemp.state.token) {
      let userInfo =(storeTemp.state.userInfo!);
      if(userInfo!==null){
        next();
      }
    } else {
      // //console.log(from.path);
      // //console.log(to.path);
      //window.localStorage.setItem('referrer',"/") //储存来源路由
      next({
        path: "/login",
        
      });
      // window.localStorage.clear();//清理浏览器缓存
    }
  } else {
    next();
  }
});

// let clientSuccess=0;
// let clientError=0;
// // let userInfo=storeTemp.state.userInfo;
// let token20=(storeTemp.state.token+"").substring(170,230);//获取TOKEN前20个字符
// let clientMark=userInfo.clientMark.split(',');//检测是否在后台登录的5个数据之一
// for(var i=0;i<clientMark.length;i++)
// {
//   // //console.log(clientMark[i]+"【】"+token20)
//   if(clientMark[i]==token20)
//   {
//     clientSuccess=1;
//     // //console.log("第"+i+"条成功"+clientMark[i]+"/"+clientSuccess)
//   }
// }
// if(clientSuccess>0)
//   {
//     next();
//   }
// else{
//   message.success("你已被挤下线啦",10);
//   next({
//     path: "/login",
//   });
//   // window.localStorage.clear();

// }

export default router;
