import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/PurUser.jpg'


const _withScopeId = n => (_pushScopeId("data-v-1d06827f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "x-link-a auth-item",
  style: {"padding":"10px"}
}
const _hoisted_2 = { class: "auth-avatar" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  class: "left-HeadPortrait"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "auth-info" }
const _hoisted_6 = { class: "auth-info-name" }
const _hoisted_7 = { class: "auth-info-statics" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.HeadPortrait == null)
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.HeadPortrait != null)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: 'https://www.aowok.com/upload/img/User/HeadPortrait/' + _ctx.HeadPortrait
          }, null, 8, _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("h3", _hoisted_6, "***" + _toDisplayString(_ctx.userName.substring(2,6)) + "***", 1),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", null, _toDisplayString(_ctx.articlesCount) + "篇文章", 1),
        _createVNode(_component_a_divider, { type: "vertical" }),
        _createElementVNode("span", null, _toDisplayString(_ctx.questionsCount) + "个问答", 1)
      ])
    ])
  ]))
}