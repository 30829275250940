import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9d819878"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "aaBox" }
const _hoisted_2 = { class: "aatitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "oneDayTopBox",
    style: _normalizeStyle({ backgroundImage: 'url(https://www.aowok.com/upload/img/keyImage/Ms' + _ctx.imagesId.substring(0, 16) + ')' })
  }, [
    _createElementVNode("a", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.gotoR(_ctx.id,_ctx.title,_ctx.imagesId.substring(0, 16) )))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.keyWord.split(',')[0] + ' aaTagTopNh')
        }, _toDisplayString(_ctx.keyWord.split(",")[0]), 3),
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.title.length > 14 ? _ctx.title.substring(0, 14) + ".." : _ctx.title), 1)
      ])
    ])
  ], 4))
}