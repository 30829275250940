
import { defineComponent, toRefs } from "vue";

export default defineComponent({
  name: "Author",
  props: {
    userName: {
      type: String,
      default: "",
    },
    articlesCount: {
      type: Number,
      default: 0,
    },
    questionsCount: {
      type: Number,
      default: 0,
    },
    HeadPortrait: {
      type: String,
      default: "",
    },
  },
  setup(props: any) {
    toRefs(props);
    return {
      props,
      HeadPortraitUrl: 'https://www.aowok.com/upload/img/User/HeadPortrait/',
    };
  },
});
