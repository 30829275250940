import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5baac1eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "aaTopBox" }
const _hoisted_2 = { class: "aaBox" }
const _hoisted_3 = ["title"]
const _hoisted_4 = {
  class: "aaTag",
  color: "default"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", _hoisted_1, [
    _createElementVNode("div", {
      class: "aaBoxImg",
      style: _normalizeStyle({ 'background-image': `url(${require('@/assets/Collocation/MS/' + Math.round(Math.random() * 144) + '.jpg')})`, })
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h4", {
          class: "aatitle",
          title: _ctx.title
        }, _toDisplayString(_ctx.title), 9, _hoisted_3),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.content.substring(0, 40) + ".."), 1)
      ])
    ], 4)
  ]))
}