import locale from "ant-design-vue/es/locale-provider";

export function NowYear (time:number) {
  var data = new Date();
  return data.getFullYear()-time
}
export function NowMonth () {
  var data = new Date();
  return data.getMonth()+1
}
export function timeago (time:string) {
  var data = new Date(time);
  var dateTimeStamp = data.getTime()
  var minute = 1000 * 60;      //把分，时，天，周，半个月，一个月用毫秒表示
  var hour = minute * 60;
  var day = hour * 24;
  var week = day * 7;
  var month = day * 30;
  var year = month * 12;
  var now = new Date().getTime();   //获取当前时间毫秒
  var diffValue = now - dateTimeStamp;//时间差

  var result = "";
  if (diffValue < 0) {
    result = "" + "未来";
  }
  var minC = diffValue / minute;  //计算时间差的分，时，天，周，月
  var hourC = diffValue / hour;
  var dayC = diffValue / day;
  var weekC = diffValue / week;
  var monthC = diffValue / month;
  var yearC = diffValue / year;

  if (yearC >= 1) {
    result = " " + parseInt(yearC.toString())  + "年前"
  } else if (monthC >= 1 && monthC < 12) {
    result = " " + parseInt(monthC.toString()) + "个月前"
  } else if (weekC >= 1 && weekC < 5 && dayC > 6 && monthC < 1) {
    result = " " + parseInt(weekC.toString()) + "周前"
  } else if (dayC >= 1 && dayC <= 6) {
    result = " " + parseInt(dayC.toString()) + "天前"
  } else if (hourC >= 1 && hourC <= 23) {
    result = " " + parseInt(hourC.toString()) + "小时前"
  } else if (minC >= 1 && minC <= 59) {
    result = " " + parseInt(minC.toString()) + "分钟前"
  } else if (diffValue >= 0 && diffValue <= minute) {
    result = ""
  }
  return result
}
export function SaleYear(){
  var strDate= new Date().getUTCFullYear()-2022;
  return(strDate);
}

export function SaleTime(){
  var date= new Date();
  // const endDate=ref(new Date(date.getFullYear()+"-"+date.getMonth()+"-"+date.getDate()+" 23:59:59").value.getTime());
  
  var year = date.getUTCFullYear().toString(); //月份从0~11，所以加一
  var month=date.getUTCMonth()+1 >9? (date.getUTCMonth()+1).toString() : "0"+(date.getUTCMonth()+1).toString() ;
  var dates=date.getDate() >9? (date.getDate()).toString() :"0"+(date.getDate()).toString() ;
  var endDate=new Date(year+"-"+(date.getUTCMonth()+1)+"-"+dates+" "+"23:59:59");
  var rundate=endDate.getTime()-date.getTime();
  var endDate2=new Date(rundate);//new Date(rundate)
  var openNow=(date.getUTCFullYear()+date.getUTCMonth()+1+date.getDate()-2000)//parseInt.toString().substring(1,2))
  var strDate ="";
  var slseNum=openNow-date.getHours();
  if(openNow==2||openNow==5||openNow==6||openNow==7||openNow==9)
  {
    
  }
  strDate =year +"年"+ month +"月"+ dates+"日#"+rundate+"#"+openNow.toString().substring(2,1)+"#"+slseNum;//+dateArr[2] +dateArr[3] +dateArr[4]
  return(strDate);
}
export function SaleTimeExp(){
  var date= new Date();
  date.setDate(date.getDate()+1095);
  // const endDate=ref(new Date(date.getFullYear()+"-"+date.getMonth()+"-"+date.getDate()+" 23:59:59").value.getTime());
  
  var year = date.getUTCFullYear().toString(); //月份从0~11，所以加一
  var month=date.getUTCMonth() >9? (date.getUTCMonth()+1).toString() : "0"+(date.getUTCMonth()+1).toString() ;
  var dates=date.getDate() >9? (date.getDate()).toString() :"0"+(date.getDate()).toString() ;
  var endDate=new Date(year+"-"+(date.getUTCMonth()+1)+"-"+dates+" "+"23:59:59");
  var openNow=(date.getUTCFullYear()+date.getUTCMonth()+1+date.getDate()-2000)//parseInt.toString().substring(1,2))
  var strDate ="";
  if(openNow==2||openNow==5||openNow==6||openNow==7||openNow==9)
  {
    
  }
  strDate =year +"年"+ month +"月"+ dates+"日";//+dateArr[2] +dateArr[3] +dateArr[4]
  return(strDate);
}
export function timeago2Date (time:string) {
  var data = new Date(time);
  var dateTimeStamp = data.getTime()
  var minute = 1000 * 60;      //把分，时，天，周，半个月，一个月用毫秒表示
  var hour = minute * 60;
  var day = hour * 24;
  var week = day * 7;
  var month = day * 30;
  var year = month * 12;
  var now = new Date().getTime();   //获取当前时间毫秒
  var diffValue = now - dateTimeStamp;//时间差

  var result = "";
  if (diffValue < 0) {
    result = "" + "未来";
  }
  var minC = diffValue / minute;  //计算时间差的分，时，天，周，月
  var hourC = diffValue / hour;
  var dayC = diffValue / day;
  var weekC = diffValue / week;
  var monthC = diffValue / month;
  var yearC = diffValue / year;

  if (yearC >= 1) {
    var m="";
    var monthD=parseInt(monthC.toString())-parseInt((yearC).toString())*12//;
    if(monthD >0)
    {
      m="零"+monthD.toString()+ "个月";
    }
    result = " " + parseInt(yearC.toString())  + "年"+ m;
  } else if (monthC >= 1 && monthC < 12) {
    result = " " + parseInt(monthC.toString()) + "个月"
  } else if (weekC >= 1 && weekC < 5 && dayC > 6 && monthC < 1) {
    result = " " + parseInt(weekC.toString()) + "周"
  } else if (dayC >= 1 && dayC <= 6) {
    result = " " + parseInt(dayC.toString()) + "天"
  } else if (hourC >= 1 && hourC <= 23) {
    result = " " + parseInt(hourC.toString()) + "小时"
  } else if (minC >= 1 && minC <= 59) {
    result = " " + parseInt(minC.toString()) + "分钟"
  } else if (diffValue >= 0 && diffValue <= minute) {
    result = "刚刚"
  }
  return result
}
export function timeago3Date (time:string,time2:string) {
  var data = new Date(time);
  var dateTimeStamp = data.getTime()
  var minute = 1000 * 60;      //把分，时，天，周，半个月，一个月用毫秒表示
  var hour = minute * 60;
  var day = hour * 24;
  var now = new Date(time2).getTime();   //获取当前时间毫秒
  var diffValue = now - dateTimeStamp;//时间差

  var result = "";
  if (diffValue < 0) {
    result = "" + "未来";
  }
  var dayC = diffValue / day;

   if (diffValue == 0 ) {
    result = "（开始）"
  }
  else if(dayC<1)
  {
    result = "（首日）"
  }
  else{
    // //console.log(dayC)
    result = "（第" + parseInt(dayC.toString()) + "日）"
  }
  return result
}
export function todayUpAndWeek (time:string) {
  var data = new Date();
  var a = new Array("日", "一", "二", "三", "四", "五", "六");  
  data.setDate(data.getDate() +parseInt(time));
  var result = data.getFullYear()+"年"+(data.getMonth()+1)+"月"+data.getDate()+"日（周"+a[data.getDay()]+"）";//+time
  return result
}
export function YYMMDD (time:string) {
  var data = new Date(time);
  var result = data.getFullYear()+"."+(data.getMonth()+1)+"."+data.getDate();
  return result
}
export function DateChinaLong (time:string) {
  var data = new Date(time);
  var result = data.getFullYear()+"年"+(data.getMonth()+1)+"月"+data.getDate()+"日 "+data.getHours()+":"+data.getMinutes();
  return result
}
export function DateChinaDate (time:string) {
  var data = new Date(time);
  var result = data.getFullYear()+"年"+(data.getMonth()+1)+"月"+data.getDate()+"日";
  return result
}
export function DateGang () {
  var data = new Date();
  var mon=data.getMonth()+1;
  var day=data.getDate();
  var result = data.getFullYear()+"-"+( mon<10?"0"+mon:mon)+"-"+( day<10?"0"+day:day);
  return result
}
export function DateMinute (time:number) {
  var data =  Math.ceil(time/60)+1 //向上取整
  return data
}

export function OutTime (urlId:string) {
  var time="20"+urlId.substring(6,8)+"-"+urlId.substring(8,10)+"-"+urlId.substring(10,12)+" "+"23:59:59";
  var data = new Date(time);
  data.setDate(data.getDate()+7);
  var result = data.getFullYear()+"年"+(data.getMonth()+1)+"月"+data.getDate()+"日";
  return result
}

export function SecondToTime(time: number){
  let a: number | string = ~~(time / 3600);
  let b: number | string = ~~(time / 60) - a * 60;
  let c: number | string = time % 60;

  a = String(a).padStart(2, "0");
  b = String(b).padStart(2, "0");
  c = String(c).padStart(2, "0");

  if (a === '00') {
      return `${b}:${c}`;
  } 
  else{
      return `${a}:${b}:${c}`;
  }
}