
import { defineComponent, onMounted, ref, reactive } from "vue";
import { Col,Modal, message,Textarea } from "ant-design-vue";
import iLetterLove from "@/components/input/LetterLove.vue";
import iLetterCharacter from "@/components/input/LetterCharacter.vue";
import iLetterMarriage from "@/components/input/LetterMarriage.vue";

import Vcode from "vue3-puzzle-vcode";
import store from "@/store";
import request from "@/api/http";
import router from "@/router";
export default defineComponent({
  name: "FooterHome",
  components: {
    Vcode,
    "a-col":Col,
    "a-modal":Modal,
    "a-textarea":Textarea,
    "b-LetterLove": iLetterLove,
    "b-LetterCharacter": iLetterCharacter,
    "b-LetterMarriage": iLetterMarriage,
  },
  setup() {
    const isShow = ref(false);
    const onShow = () => {
      isShow.value = true;
    };

    const onClose = () => {
      isShow.value = false;
    };
    const onSuccess = () => {
      onClose(); // 验证成功，手动关闭模态框
      handleSendEamil();
    };
    const visibleLove = ref<boolean>(false);
    const visibleCharacter = ref<boolean>(false);
    const visibleMarriage = ref<boolean>(false);

    const showLove = () => {
      visibleLove.value = true;
    };
    const showCharacter = () => {
      visibleCharacter.value = true;
    };
    const showMarriage = () => {
      visibleMarriage.value = true;
    };
    const handleLove = (e: MouseEvent) => {
      visibleLove.value = false;
    };
    const handleCharacter = (e: MouseEvent) => {
      visibleCharacter.value = false;
    };
    const handleMarriage = (e: MouseEvent) => {
      visibleMarriage.value = false;
    };
    let email = reactive({
      comment:"",//email.comment
    });
    const handleSendEamil = () => {
if (email.comment.length < 10||email.comment.length >500) {
        return message.error("请输入(10-500字)的内容",3);
      }else {
      request({
          url: "/HomeApi/SendEmail",
          method: "post",
          data: {
            url: router.currentRoute.value.fullPath,
            comment: email.comment,
          },
        }).then((res: any) => {
          if (res.data.success) {
              message.success("已提交至作者邮箱，如你已在内容中填写联系方式，我们将尽快给与回复，感谢你对本真缘梦的支持。",5);
          } else {
            message.error(res.data.msg);
          }
        });
      }
    };
    return{
      onShow,isShow,onClose,onSuccess,handleSendEamil,email,
      visibleLove,
      visibleCharacter,
      visibleMarriage,
      showLove,
      showCharacter,
      showMarriage,
      handleLove,
      handleCharacter,
      handleMarriage,
    }
  }
});
